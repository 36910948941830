import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { facadeFactory, provideDefaultConfigFactory, UserProfileFacadeTransitionalToken } from '@spartacus/core';
import '@spartacus/storefront';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const USER_PROFILE_FEATURE = 'userProfile';
const USER_PROFILE_CORE_FEATURE = 'userProfileCore';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class UserProfileFacade {
  static {
    this.ɵfac = function UserProfileFacade_Factory(t) {
      return new (t || UserProfileFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserProfileFacade,
      factory: () => (() => facadeFactory({
        facade: UserProfileFacade,
        feature: USER_PROFILE_CORE_FEATURE,
        methods: ['get', 'update', 'close', 'getTitles']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserProfileFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: UserProfileFacade,
        feature: USER_PROFILE_CORE_FEATURE,
        methods: ['get', 'update', 'close', 'getTitles']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
// TODO: Inline this factory when we start releasing Ivy compiled libraries
function defaultUserProfileComponentsConfig() {
  const config = {
    featureModules: {
      [USER_PROFILE_FEATURE]: {
        cmsComponents: ['RegisterCustomerComponent', 'UpdateProfileComponent', 'UpdateEmailComponent', 'UpdatePasswordComponent', 'ForgotPasswordComponent', 'ResetPasswordComponent', 'CloseAccountComponent', 'AccountAddressBookComponent']
      },
      // by default core is bundled together with components
      [USER_PROFILE_CORE_FEATURE]: USER_PROFILE_FEATURE
    }
  };
  return config;
}
class UserProfileRootModule {
  static {
    this.ɵfac = function UserProfileRootModule_Factory(t) {
      return new (t || UserProfileRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UserProfileRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfigFactory(defaultUserProfileComponentsConfig), {
        provide: UserProfileFacadeTransitionalToken,
        useExisting: UserProfileFacade
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserProfileRootModule, [{
    type: NgModule,
    args: [{
      providers: [provideDefaultConfigFactory(defaultUserProfileComponentsConfig), {
        provide: UserProfileFacadeTransitionalToken,
        useExisting: UserProfileFacade
      }]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class UserEmailFacade {
  static {
    this.ɵfac = function UserEmailFacade_Factory(t) {
      return new (t || UserEmailFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserEmailFacade,
      factory: () => (() => facadeFactory({
        facade: UserEmailFacade,
        feature: USER_PROFILE_CORE_FEATURE,
        methods: ['update']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserEmailFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: UserEmailFacade,
        feature: USER_PROFILE_CORE_FEATURE,
        methods: ['update']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class UserPasswordFacade {
  static {
    this.ɵfac = function UserPasswordFacade_Factory(t) {
      return new (t || UserPasswordFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserPasswordFacade,
      factory: () => (() => facadeFactory({
        facade: UserPasswordFacade,
        feature: USER_PROFILE_CORE_FEATURE,
        methods: ['update', 'reset', 'requestForgotPasswordEmail']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserPasswordFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: UserPasswordFacade,
        feature: USER_PROFILE_CORE_FEATURE,
        methods: ['update', 'reset', 'requestForgotPasswordEmail']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class UserRegisterFacade {
  static {
    this.ɵfac = function UserRegisterFacade_Factory(t) {
      return new (t || UserRegisterFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserRegisterFacade,
      factory: () => (() => facadeFactory({
        facade: UserRegisterFacade,
        feature: USER_PROFILE_CORE_FEATURE,
        methods: ['register', 'registerGuest', 'getTitles']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserRegisterFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: UserRegisterFacade,
        feature: USER_PROFILE_CORE_FEATURE,
        methods: ['register', 'registerGuest', 'getTitles']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/** AUGMENTABLE_TYPES_END */

/**
 * Generated bundle index. Do not edit.
 */

export { USER_PROFILE_CORE_FEATURE, USER_PROFILE_FEATURE, UserEmailFacade, UserPasswordFacade, UserProfileFacade, UserProfileRootModule, UserRegisterFacade, defaultUserProfileComponentsConfig };
